<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreateRequest"
        variant="primary"
        @click="openModal()"
      >
        Create request
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :columns="columns"
      :resource="resource"
      :filters="filters"
    />
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <balance-change-request-editor
        :entity="entity"
        @submit="handleSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  balanceChanges,
  passDataToResource,
  balanceChangeRequestStatuses
} from '@/services/resources'
import { VxButton } from '@/components/buttons'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { buildRoute, path } from '@/router'
import { filterTypes, sortOrders } from '@/services/table'
import {
  VxCellBadges, VxCellButtons,
  VxCellDate,
  VxCellLink,
  VxCellMoney,
  VxCellPlayerAvatar,
  VxCellTooltip,
  VxTable
} from '@/components/table'
import store from '@/store'
import { permissions, useCan } from '@/plugins/acl'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { ref, computed } from '@vue/composition-api'
import BalanceChangeRequestEditor from '@/views/balance-changes/BalanceChangeRequestEditor'
import { BModal } from 'bootstrap-vue'
import { useModalEditor } from '@/services/modal'
import { formatToCents, formatToDecimal } from '@/services/utils'

export default {
  name: 'BalanceChangeRequests',
  components: {
    BModal,
    BalanceChangeRequestEditor,
    VxTable,
    VxCellTooltip,
    VxCellPlayerAvatar,
    VxCellLink,
    VxCellMoney,
    VxCellDate,
    VxPageTitleActions,
    VxButton
  },
  setup () {
    const tableRef = ref(null)

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()

    const resource = passDataToResource(balanceChanges.getAllRequests, {
      requestParams: {
        params: { include: 'account,account.player,creator,reviewer' }
      }
    })
    const canCreateRequest = useCan(permissions.balanceChangeRequestCreate)
    const canReviewOwn = useCan(permissions.balanceChangeRequestReviewOwn)
    const canReviewAnother = useCan(permissions.balanceChangeRequestReviewAnother)
    const currentUserId = store.getters['user/id']

    const modalTitle = computed(() => {
      return (entity.value ? 'Update' : 'Create') + ' change request'
    })

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'account.player',
        label: 'Player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'amount',
        component: VxCellMoney
      },
      {
        key: 'reason',
        component: VxCellTooltip
      },
      {
        key: 'creator',
        component: VxCellLink,
        tdAttr: (creator) => {
          if (!creator) return {}
          const { id, first_name, last_name, role: { title } } = creator
          return {
            label: `${first_name} ${last_name}`,
            subLabel: `#${id} ${title}`,
            href: buildRoute(path.admin, { params: { id } })
          }
        }
      },
      {
        key: 'reviewer',
        component: VxCellLink,
        tdAttr: (reviewer) => {
          if (!reviewer) return {}
          const { id, first_name, last_name, role: { title } } = reviewer
          return {
            label: `${first_name} ${last_name}`,
            subLabel: `#${id} ${title}`,
            href: buildRoute(path.admin, { params: { id } })
          }
        }
      },
      {
        key: 'status',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [balanceChangeRequestStatuses.approved]: 'success',
            [balanceChangeRequestStatuses.pending]: 'warning',
            [balanceChangeRequestStatuses.rejected]: 'danger'
          }
        }
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item),
          vertical: true
        })
      },
      {
        key: 'created_at',
        component: VxCellDate
      },
      {
        key: 'updated_at',
        component: VxCellDate
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'creator_id',
        type: filterTypes.number
      },
      {
        key: 'reviewer_id',
        type: filterTypes.number
      },
      {
        key: 'amount',
        type: filterTypes.number,
        formatterSetter: formatToCents,
        formatterGetter: formatToDecimal
      },
      {
        key: 'reason',
        type: filterTypes.text
      },
      {
        key: 'status',
        type: filterTypes.select,
        options: Object.values(balanceChangeRequestStatuses)
      },
      {
        key: 'created_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['from', 'to']
      }
    ]

    const actionButtons = ({ id, status, creator_id } = {}) => {
      if (status !== balanceChangeRequestStatuses.pending) return []

      const currentUserIsCreator = currentUserId === creator_id
      const canReview = currentUserIsCreator ? canReviewOwn : canReviewAnother

      const payload = {
        requestParams: { urlParams: { id } }
      }

      const actionButtons = []
      const approveButton = {
        ...buttons.approve,
        resource: passDataToResource(balanceChanges.approveRequest, payload)
      }
      const rejectButton = {
        ...buttons.reject,
        resource: passDataToResource(balanceChanges.rejectRequest, payload)
      }
      const editButton = {
        ...buttons.edit,
        onClick: openModal
      }

      if (currentUserIsCreator) actionButtons.push(editButton)

      if (canReview) actionButtons.push(...[approveButton, rejectButton])

      return actionButtons
    }

    const handleSubmit = () => {
      closeModal()
      tableRef.value.refresh()
    }

    return {
      tableRef,
      resource,
      columns,
      filters,

      canCreateRequest,
      entity,
      modalTitle,
      modalIsOpen,

      openModal,
      closeModal,
      handleSubmit
    }
  }
}
</script>
